@mixin dark-theme {
  // Overrides
  --pico-mark-color: #{$primary};
  --pico-primary-hover: #{$primary};
  --pico-background-color: #{$swamp};

  // Main colors
  --primary: #{$primary};
  --primary-dark: #{$primary-dark};
  --primary-light: #{$primary-light};

  // Header & mobile menu
  --header-title-color: #{$dew};
  --header-title-color-underline: #{$primary};
  --header-menu-line-color: #{$dew};
  --header-menu-line-hover-color: #{$primary};
  --header-menu-link-color: #{$dew};
  --header-menu-link-border-color: #{$primary};
  --mobile-fullscreen-menu-background-color: #{$swamp-95};

  // Hero
  --hero-title-color: #{$dew};
  --hero-underline-color: #{$primary};
  --hero-em-color: #{$primary};

  // Gallery
  --gallery-hover-color: #{$dew};
  --gallery-hover-background-color: #{$transparent-black-80};

  // Footer
  --footer-text-color: #{$pewter};
  --footer-social-hover-color: #{$primary};

  // About
  --about-definition-color: #{$twilight-blue};
  --about-definition-background-color: #{$mine-shaft};
}
