.d-flex {
  display: flex;
}

.d-content-space-between {
  justify-content: space-between;
}

@include phone {
  .d-flex {
    flex-flow: column;
  }
}
