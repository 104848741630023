//***********************************************************************************************
//
//    Responsive Mixins - Configuration
//
//***********************************************************************************************

$phone-min-width: 320px;
$phone-width: 576px;
$tablet-width: 768px;
$small-desktop-width: 1024px;
$large-desktop-width: 1280px;
$extra-large-desktop-width: 1536px;


//***********************************************************************************************
//
//    Responsive Mixins - Styles
//
//***********************************************************************************************

@mixin phone {
    @media (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin small-phone {
    @media (max-width: #{$phone-width - 1px}) {
        @content;
    }
}

@mixin large-phone {
    @media (min-width: #{$phone-width - 1px}) and (max-width: #{$tablet-width - 1px}) {
        @content;
    }
}

@mixin phone-tablet {
    @media (max-width: #{$small-desktop-width}) {
        @content;
    }
}

@mixin tablet {
    @media (min-width: #{$tablet-width}) and (max-width: #{$small-desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet-desktop {
    @media (min-width: #{$tablet-width}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$small-desktop-width}) {
        @content;
    }
}

@mixin small-desktop {
    @media (min-width: #{$small-desktop-width}) and (max-width: #{$large-desktop-width - 1px}) {
        @content;
    }
}

@mixin large-desktop {
    @media (min-width: #{$large-desktop-width}) {
        @content;
    }
}


@mixin extra-large-desktop {
    @media (min-width: #{$extra-large-desktop-width}) {
        @content;
    }
}
