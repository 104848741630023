.no-margin {
  margin: 0;
}

.no-bottom-margin {
  margin-bottom: 0 !important;
}

.text-center {
  text-align: center;
}

.sm-md-only,
.xxl-only {
  display: none;
}

@include phone-tablet {
  .sm-md-only {
    display: initial;
  }
}

@include large-desktop {
  .xxl-only {
    display: initial;
  }
}
