.footer__container {
  padding: 0;
}

.footer {
  background: var(--footer-background-color);
  color: var(--footer-text-color);
  padding: 0 20px 3rem;
  font-family: var(--footer-font-family);

  h2 {
    color: var(--footer-title-color);
    margin: 0 0 .5em;
    text-align: center;
  }

  p {
    text-align: center;
    color: var(--footer-text-color);
  }

  .footer__description {
    margin: 0;
  }

  .copyright {
    margin: 0;
    font-size: .875rem;
    font-weight: 300;
  }
}

@include tablet-desktop {
  .footer__content {
    margin: 0 auto;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;

    h2,
    p {
      text-align: left;
    }
  }

  .footer__column {
    max-width: 60%;
  }
}
