:root {
  --pico-font-family: 'Josefin Sans', sans-serif;
}

h2 {
  --typography-spacing-vertical: 2rem;
}

h3 {
  --typography-spacing-vertical: 1.5rem;
  --pico-font-size: 2rem;
  font-weight: 400;

  strong {
    font-weight: 400;
    color: $primary;
  }
}

a {
  --pico-color: var(--link-color);
  --pico-underline: var(--link-color);

  &, &:hover {
    cursor: pointer;
  }
}

table {
  th {
    font-weight: bold;
  }
}
