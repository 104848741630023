// Light theme (Default)
// Can be forced with data-theme="light"
@import "./light";

// Dark theme (Auto)
// Automatically enabled if user has Dark mode enabled
@import "./dark";
@media only screen and (prefers-color-scheme: dark) {
  :root:not([data-theme="light"]) {
    @include dark-theme;
  }
}

// Dark theme (Forced)
// Enabled if forced with data-theme="dark"
[data-theme="dark"] {
  @include dark-theme;
}

:root {
  // Theme switch colors
  --theme-switch-sun-color: #{$swamp};
  --theme-switch-sun-hover: #{$mosque};
  --theme-switch-moon-color: #{$dew};
  --theme-switch-moon-hover: #{$primary};
  --theme-switch-icon-size: 24px;

  // Theme general variables
  --hero-font-size: 3rem;
  --hero-font-family: "Libre Bodoni", serif;
  --section-padding: 3rem;
  --gallery-hover-font-family: "Bodoni Moda", serif;
  --footer-font-family: 'Roboto', Arial, sans-serif;

}
