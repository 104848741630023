.hero {
  height: 15rem;
  width: 100vw;
  display: table-cell;
  vertical-align: middle;
  padding: 2rem;
  text-align: center;
  background-position: 0 70%;
  background-size: cover;
  background-blend-mode: soft-light;
  background-color: rgba(255, 255, 255, 0.5);

  h2 {
    margin: 0;
    color: var(--hero-title-color);
    font-size: var(--hero-font-size);
    font-family: var(--hero-font-family);
    font-style: italic;
    font-weight: 400;
  }
}

.hero:not(.hero--home) {
  span {
    display: inline-block;
    background-image: linear-gradient(var(--hero-underline-color) 0, var(--hero-underline-color) 100%);
    background-position: 0 93%;
    background-size: 100% 3px;
    background-repeat: no-repeat;
  }
}

.hero.hero--logo-et-lettrage span {
  padding: 0 5px 0 10px;
}

.hero.hero--a-propos span {
  padding: 0 2px 0 13px;
}

.hero--home {
  span:first-child {
    vertical-align: top;
  }

  em {
    color: var(--hero-em-color);
    font-size: 7rem;
    vertical-align: middle;
    margin: 0 -.5rem 0 -1.5rem;
    font-family: "Playfair Display", serif;
  }

  span:last-child {
    vertical-align: bottom;
  }
}

@include dark-theme {
  .hero {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

@include phone {
  .hero--home {
    em {
      font-size: 4rem;
      display: block;
      margin: 0;
    }
  }
}
