@mixin animated-underline--active($border-color: #000, $border-size: 2px, $border-bottom-padding: 2px) {
  background-position: 100% 100%; /*OR bottom right*/
  background-size: 100% $border-size;
}

@mixin animated-underline($border-color: #000, $border-size: 2px, $border-bottom-padding: 2px) {
  display: inline-block;
  padding: 0 0 $border-bottom-padding;
  background-image: linear-gradient($border-color 0, $border-color 100%);
  background-position: 0 100%;
  background-size: 0 $border-size;
  background-repeat: no-repeat;
  transition: background-size .3s, background-position 0s .3s;

  &:hover,
  &:focus,
  &:active {
    @include animated-underline--active($border-color, $border-size, $border-bottom-padding);
  }
}

.animated-underline {
  @include animated-underline(var(--primary));
}
