// @see https://codepen.io/alphardex/pen/NWxNyXb
$burger-menu-radius: 2.5rem !default;
$overlay-menu-z-index: 9 !default;
$burger-menu-z-index: 10 !default;

#burger-toggle {
  display: none;

  &:checked {
    & ~ .menu {
      opacity: 1;
      display: flex;
      visibility: visible;

      .menu-nav-link span span {
        transform: translateY(0);
        transition: 1.2s 0.1s cubic-bezier(0.35, 0, 0.07, 1);
        font-weight: bold;
      }
    }

    & ~ .burger-toggle__label {
      .line {
        background: var(--header-menu-line-color);

        &::after {
          transform: translateX(0);
          background: var(--header-menu-line-color);
        }

        &:nth-child(1) {
          transform: translateY(calc($burger-menu-radius / 5))
          rotate(45deg);
        }

        &:nth-child(2) {
          transform: scaleX(0);
        }

        &:nth-child(3) {
          transform: translateY(calc($burger-menu-radius / -5))
          rotate(-45deg);
        }
      }

      &:hover .line::after {
        background: var(--header-menu-line-hover-color);
      }
    }
  }
}

.burger-toggle__label {
  text-transform: uppercase;
  margin: -.45rem 0 0;

  &:hover,
  &:focus,
  &:active {
    .line::after {
      transform: translateX(0);
    }

    .burger-menu__label {
      color: var(--header-menu-line-hover-color);
    }
  }
}

.burger-menu__icon {
  position: relative;
  z-index: $burger-menu-z-index;
  display: inline-block;
  width: $burger-menu-radius;
  height: $burger-menu-radius;
  outline: none;
  cursor: pointer;
  vertical-align: middle;

  .line {
    position: absolute;
    left: 25%;
    width: 50%;
    height: 3px;
    background: var(--header-menu-line-color);
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s;

    &:nth-child(1) {
      top: 32%;
    }

    &:nth-child(2) {
      top: 50%;
    }

    &:nth-child(3) {
      top: 69%;
    }

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--header-menu-line-hover-color);
      transform: translateX(-100%);
      transition: 0.25s;
    }

    @for $i from 2 through 3 {
      &:nth-child(#{$i})::after {
        transition-delay: 0.1s * ($i - 1);
      }
    }
  }
}

.burger-menu__label {
  display: inline-block;
  vertical-align: middle;
  line-height: 2rem;
  color: var(--header-menu-line-color);
  margin: .5rem 0 0 -5px;
}

.header__menu {
  position: relative;

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: $overlay-menu-z-index;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: var(--mobile-fullscreen-menu-background-color);
    opacity: 0;
    display: none;
    overflow-x: hidden;
    visibility: hidden;
    transition: 0.3s;

    .menu-inner {
      margin: auto;
    }

    &-nav {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      height: 100%;
      text-align: center;
      list-style-type: none;
      flex-direction: column;
      justify-content: space-evenly;

      &-item {
        flex: 1;
        display: flex;
        align-items: center;
      }

      &-link {
        position: relative;
        display: inline-flex;
        font-size: 2rem;
        color: var(--header-menu-link-color);
        text-decoration: none;

        & > span {
          overflow: hidden;

          span {
            transform: translateY(102%);
            display: block;
          }
        }

        &::after {
          position: absolute;
          content: "";
          top: 100%;
          left: 0;
          width: 100%;
          height: 3px;
          background: var(--header-menu-link-border-color);
          transform: scaleX(0);
          transform-origin: right;
          transition: transform 0.5s;
        }

        &:active,
        &:focus,
        &:hover {
          &::after {
            transform: scaleX(1);
            transform-origin: left;
          }
        }
      }
    }
  }
}
