.gallery {
  padding: 0;
  margin: 1rem 0 3rem;
  width: 100%;
  display: grid;
  gap: .5rem;

  li {
    list-style-type: none;
    width: 100%;

    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      display: block;
    }
  }
}

@include tablet {
  .gallery {
    &--four-cols {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
}

@include tablet-desktop {
  .gallery {
    &--three-cols {
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }
}

@include desktop {
  .gallery {
    &--two-cols {
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    &--four-cols {
      grid-template-columns: repeat(4, 1fr);
      gap: 1rem;
    }
  }
}
