// Common
$assets-folder: '..';
$icons-folder: '../static/ico';
$images-folder: '../img';
$svg-folder: '../static/svg';
$fonts-folder: '../static/fonts';

// Color
$logo-color: #3a5667;
$white: #fff;
$black: #000;

// Motion
@custom-media --motionOK (prefers-reduced-motion: no-preference);
