$logo-size: 2.5rem !default;
$header-underline-size: 3px;
$header-underline-padding: 3px;

.main-header {
  flex-flow: row;
  align-items: center;
  padding: 0;
  min-width: 300px;

  h1 {
    margin: 0;
    padding: 0;
    max-height: $logo-size;
    font-family: var(--pico-font-family);
  }

  .header__logo {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 300;
    color: var(--header-title-color);
    text-decoration: none;

    &::before {
      display: inline-block;
      background: url('#{$icons-folder}/favicon.svg') no-repeat center center;
      background-size: cover;
      overflow: hidden;
      width: $logo-size;
      height: $logo-size;
      content: " ";
      margin: -1rem .5rem 0 0;
    }
  }

  .header__logo--animated-underline {
    & > span {
      @include animated-underline(var(--header-title-color-underline), $header-underline-size, $header-underline-padding);
    }

    &:hover,
    &:focus,
    &:active {
      & > span {
        @include animated-underline--active(var(--header-title-color-underline), $header-underline-size, $header-underline-padding);
      }
    }
  }

  .animated-drop-text--header {
    @include animated-drop-text(15, .4s, .1s);

    .space {
      margin: 0 .25rem;
    }
  }
}

.main-header__right {
  display: flex;
  align-items: center;
}

@include phone-tablet {
  .main-header__right {
    flex-flow: row-reverse;
    gap: 1rem;
  }
}

@include dark-theme {
  .main-header {
    .header__logo {
      &::before {
        background-image: url('#{$icons-folder}/favicon-reversed.svg');
      }
    }
  }
}
