// Default: Light theme
[data-theme="light"],
:root:not([data-theme="dark"]) {
  // Overrides
  --pico-mark-color: #{$primary};
  --pico-primary-hover: #{$primary};

  // Main colors
  --primary: #{$primary};
  --primary-dark: #{$primary-dark};
  --primary-light: #{$primary-light};

  // Header & mobile menu
  --header-title-color: #{$swamp};
  --header-title-color-underline: #{$primary};
  --header-menu-line-color: #{$swamp};
  --header-menu-line-hover-color: #{$primary};
  --header-menu-link-color: #{$swamp};
  --header-menu-link-border-color: #{$primary-light};
  --mobile-fullscreen-menu-background-color: #{$twilight-blue-95};

  // Hero
  --hero-title-color: #{$swamp};
  --hero-underline-color: #{$primary};
  --hero-em-color: #{$primary};

  // Gallery
  --gallery-hover-color: #{$swamp};
  --gallery-hover-background-color: #{$twilight-blue-95};

  // Footer
  --footer-text-color: #{$abbey};
  --footer-social-hover-color: #{$primary};

  // About
  --about-definition-color: #{$swamp};
  --about-definition-background-color: #{$seashell};
}
