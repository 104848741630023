@import "../external-libs/easings";
@import "../themes/sun-and-moon";

.theme-toggle {
  --size: var(--theme-switch-icon-size);
  --icon-fill: var(--theme-switch-sun-color);
  --icon-fill-hover: var(--theme-switch-sun-hover);

  [data-theme="dark"] & {
    --icon-fill: var(--theme-switch-moon-color);
    --icon-fill-hover: var(--theme-switch-moon-hover);
  }

  background: none;
  border: none;
  padding: 0;
  margin: 0;

  inline-size: var(--size);
  block-size: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;

  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;

  outline-offset: 5px;

  & > svg {
    inline-size: 100%;
    block-size: 100%;
    stroke-linecap: round;
    transition: transform .7s ease-in-out;
    transform: rotate(0deg);
    margin: -0.5rem 0 0;
  }

  &:active,
  &:focus,
  &:hover {
    --pico-box-shadow: none;

    & > svg {
      transform: rotate(10deg);

      .sun {
        fill: $primary !important;
      }
    }
  }
}
