$base-image-size: 1;
$hover-image-size: 1.1;

.animated-image-container {
  display: block;
  overflow: hidden;
  border-radius: var(--rounded-image-border-radius);

  &,
  img {
    transform: scale($base-image-size);
    transition: transform .2s var(--ease-1);
  }

  &:hover {
    img {
      transform: scale($hover-image-size) translate(0, 4%);
    }
  }
}

.rounded-image {
  border-radius: var(--rounded-image-border-radius);
}
