@mixin animated-drop-text($totalChars: 10, $duration: .4s, $delay: .1s) {
  position: relative;
  display: flex;

  @for $i from 1 through $totalChars {
    & > :nth-child(#{$i}) {
      opacity: 0;
      animation: drop-text $duration linear forwards #{$i * $delay};
    }
  }
}

@keyframes drop-text {
  0% { transform: translateY(-200px) scaleY(0.9); opacity: 0; }
  5% { opacity: .7; }
  50% { transform: translateY(0px) scaleY(1); opacity: 1; }
  65% { transform: translateY(-17px) scaleY(.9); opacity: 1; }
  75% { transform: translateY(-22px) scaleY(.9); opacity: 1; }
  100% { transform: translateY(0px) scaleY(1); opacity: 1; }
}

.animated-drop-text {
  @include animated-drop-text(10, .4s, .1s);
}
